import React, { useState, useEffect } from "react";
import Page from "../components/Page";
import {
  ButtonGroup,
  Button,
  Container,
  Typography,
  Box,
  Stack,
  Chip,
} from "@mui/material";
import { useAuth } from "src/contexts/AuthContext";
import { db } from "src/firebase";
import OrderDetails from "src/components/OrderDetails";
import OrderMap from "src/components/OrderMap";
import { truncate } from "lodash";
import { Howl } from "howler";

function playSound() {
  const sound = new Howl({
    src: ["/newOrderSound.mp3"],
    html5: true,
  });
  sound.play();
}

function fancyTimeFormat(duration) {
  // Hours, minutes and seconds
  var hrs = ~~(duration / 3600);
  var mins = ~~((duration % 3600) / 60);
  var secs = ~~duration % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = "";

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;
  return ret;
}
function Orders() {
  const { school } = useAuth();
  const [orderMode, setOrderMode] = useState("Active");
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState("");
  const [times, setTimes] = useState({});
  const [handlers, setHandlers] = useState([]);
  const [mostRecentOrderID, setMostRecentOrderID] = useState("");
  const [handlerLocations, setHandlerLocations] = useState([]);

  async function getHandlerLocations() {
    console.log("gettingHandlerLocations for " + handlers.length + " handlers");
    let locations = [];
    handlers.forEach((handler) => {
      console.log("Attempting to fetch location for " + handler.displayName);
      db.collection("users")
        .doc(handler.id)
        .collection("otherData")
        .doc("current")
        .get()
        .then((doc) => {
          console.log("Fetch complete for " + handler.displayName);
          if (doc.exists) {
            locations.push({ displayName: handler.displayName, ...doc.data() });
            console.log("Got data: ", doc.data());
            setHandlerLocations([...locations]);
          } else {
            console.log("No location for " + handler.displayName);
          }
        });
      console.log("Locations: ", handlerLocations.length);
    });
  }

  async function getOrders() {
    console.log("getting orders");
    db.collection(`universities/${school}/orders`)
      .orderBy("timestamp", "desc")
      .limit(250)
      .get()
      .then((query) => {
        let arr = [];
        query.forEach((doc) => {
          let obj = doc.data();
          obj.id = doc.id;
          arr.push(obj);
        });
        if (mostRecentOrderID !== arr[0].id) {
          if (mostRecentOrderID !== "") {
            console.log("Alerting");
            playSound();
          } else {
          }
        }
        setMostRecentOrderID(arr[0].id);
        setOrders([...arr]);
      });
  }

  useEffect(() => {
    getOrders();
    getHandlerLocations();
    let interval = setInterval(() => {
      getOrders();
      getHandlerLocations();
    }, 5000);
    return () => clearInterval(interval);
  }, [mostRecentOrderID, handlers]);

  useEffect(() => {
    let interval = setInterval(() => {
      if (orders) {
        let obj = {};
        orders.forEach((order) => {
          if (!order.delivered_timestamp) {
            let secs = 0;
            let now = new Date();
            secs = now.getTime() / 1000 - order.timestamp.seconds;
            if (secs) {
              obj[order.id] = Number(secs.toFixed(0));
            }
          }
        });
        setTimes(obj);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [orders]);

  useEffect(() => {
    let unsub = db
      .collection(`users`)
      .where("type", "==", "handler")
      .where("school", "==", school)
      .where("online", "==", true)
      .onSnapshot((query) => {
        let arr = [];
        query.forEach((doc) => {
          let obj = doc.data();
          obj.id = doc.id;
          arr.push(obj);
        });
        setHandlers([...arr]);
      });

    return unsub;
  }, []);

  return (
    <Box
      sx={{
        height: "100%",
        flex: 1,
        color: "#1b1558",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box sx={{ width: "60%", height: "100%" }}>
        <Box>
          <ButtonGroup
            fullWidth
            variant="contained"
            aria-label="outlined primary button group"
          >
            <Button
              onClick={() => {
                setOrderMode("Active");
              }}
              color={orderMode !== "Active" ? "info" : "secondary"}
            >
              Active
            </Button>
            <Button
              onClick={() => {
                setOrderMode("Delivered");
              }}
              color={orderMode === "Active" ? "info" : "secondary"}
            >
              Delivered
            </Button>
          </ButtonGroup>
        </Box>
        <Box
          sx={{
            display: "grid",
            gap: 1,
            gridTemplateColumns: "repeat(2, 2fr)",
            height: "75vh",
          }}
        >
          <Box
            sx={{
              borderStyle: "dashed",
              borderWidth: 1,
              borderColor: "black",
              overflowY: "hidden", // added scroll
            }}
          >
            <Box
              sx={{
                overflow: "hidden",
                overflowY: "scroll", // added scroll
                maxHeight: "100%",
              }}
            >
              {orders.map((item, index) => {
                if (
                  (orderMode === "Active" &&
                    (item.status === "Received" ||
                      item.status === "Packing" ||
                      item.status === "Packed" ||
                      item.status === "En Route")) ||
                  (orderMode === "Delivered" &&
                    (item.status === "Delivered" ||
                      item.status === "Cancelled"))
                ) {
                  return (
                    <OrderListItem
                      selectedOrder={selectedOrder}
                      setSelectedOrder={setSelectedOrder}
                      data={item}
                      key={index}
                      times={times}
                    />
                  );
                }
              })}
            </Box>
          </Box>
          <Box
            sx={{
              borderStyle: "dashed",
              borderWidth: 1,
              borderColor: "black",
              padding: 2,
              overflowY: "scroll",
            }}
          >
            <OrderDetails
              getOrders={getOrders}
              orders={orders}
              selectedOrder={selectedOrder}
            />
          </Box>
        </Box>
        <Stack sx={{ p: 1 }} direction="row" justifyContent={"space-around"}>
          {handlers.map((item, index) => {
            return (
              <Box
                style={{
                  padding: 8,
                  borderStyle: "solid",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (selectedOrder) {
                    db.collection(`universities/${school}/orders`)
                      .doc(selectedOrder)
                      .set(
                        {
                          courier: item.id,
                          courierName: item.displayName,
                        },
                        { merge: true }
                      )
                      .then(() => {
                        getOrders();
                      });
                  }
                }}
              >
                <span>{item.displayName}</span>
              </Box>
            );
          })}
        </Stack>
      </Box>
      <Box style={{ width: "40%", height: "100%" }}>
        <OrderMap
          setSelectedOrder={setSelectedOrder}
          orders={orders}
          couriers={handlerLocations}
          selectedOrder={selectedOrder}
          orderMode={orderMode}
        />{" "}
      </Box>
    </Box>
  );
}

export default Orders;

function OrderListItem({ data, setSelectedOrder, selectedOrder, times }) {
  let active = true;

  if (data.status === "Delivered" || data.status === "Cancelled") {
    active = false;
  }

  let backgroundColor = data.status === "Packing" ? "##e74c3c" : "#ecf0f1";

  if (selectedOrder === data.id) {
    backgroundColor = "#bdc3c7";
  }

  let timeLabel;
  let timeLevel;
  let minStr;
  let secStr;
  if (!active) {
    if (data.delivered_timestamp) {
      let secs = data.delivered_timestamp.seconds - data.timestamp.seconds;
      timeLabel = fancyTimeFormat(secs);
      timeLevel = "success";
      if (secs >= 600) {
        timeLevel = "warning";
      }
      if (secs >= 900) {
        timeLevel = "error";
      }
    } else {
      timeLabel = "Cancelled";
      timeLevel = "warning";
    }
  } else {
    if (timeLabel !== "Cancelled") {
      timeLabel = fancyTimeFormat(times[data.id]);
      timeLevel = "info";
      if (times[data.id] >= 600) {
        timeLevel = "warning";
      }
      if (times[data.id] >= 900) {
        timeLevel = "error";
      }
    }
  }

  function getStatusChipBackgroundColor(status) {
    switch (status) {
      case "Recieved":
        return "warning";
      case "Packing":
        return "warning";
      case "En Route":
        return "success";
      case "Delivered":
        return "info";
    }
  }

  return (
    <Box
      onClick={() => {
        setSelectedOrder(data.id);
      }}
      sx={{
        backgroundColor: backgroundColor,
        padding: "8px",
        borderColor: "#bdc3c7",
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        cursor: "pointer",
        width: "100%",
      }}
      style={{ width: "100%" }}
    >
      <Stack
        direction="row"
        justifyContent="space-evenly"
        alignItems={"center"}
        spacing={2}
        style={{ width: "100%" }}
      >
        <Stack style={{ width: "100%" }} direction="column">
          <Stack direction="row" justifyContent={"space-between"}>
            <Chip color={timeLevel} label={timeLabel} />
            <Typography variant="h5">{data.customer}</Typography>
            <Chip
              color={getStatusChipBackgroundColor(data.status)}
              label={data.status}
            />
          </Stack>
          <Stack direction="row" justifyContent="space-between" sx={{ mt: 1 }}>
            <Typography noWrap variant="p">
              {data.address.substring(0, data.address.indexOf(","))}
            </Typography>
            <Typography noWrap variant="p">
              {data.courierName ? data.courierName : ""}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}
