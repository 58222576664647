import { db } from "../firebase";

export default async function CancelOrder(uni, id, refund) {
  let orderDoc = await db
    .collection(`universities/${uni}/orders`)
    .doc(id)
    .get();
  orderDoc = orderDoc.data();
  console.log("Got order data");
  await db.collection(`universities/${uni}/orders`).doc(id).set(
    {
      status: "Cancelled",
    },
    { merge: true }
  );
  console.log("Updated status");
  if (refund) {
    console.log("Attempting refund");
    let userDoc = await db.collection("users").doc(orderDoc.customerID).get();
    console.log("Got customer");
    await db
      .collection("users")
      .doc(orderDoc.customerID)
      .set(
        {
          storeCredit:
            userDoc.data().storeCredit +
            Number(orderDoc.total) +
            orderDoc.storeCreditUsed,
        },
        { merge: true }
      );
    console.log("Deployed refund");
  }
}
