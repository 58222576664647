import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import Login from "./pages/Login";
import Register from "./pages/Register";
import DashboardApp from "./pages/DashboardApp";
import Products from "./pages/Products";
import Discounts from "./pages/Discounts";
import User from "./pages/User";
import Orders from "./pages/Orders";
import NotFound from "./pages/Page404";
import { useAuth } from "./contexts/AuthContext";
import Supply from "./pages/Supply";
import NewSupplier from "./pages/NewSupplier";
import NewOrder from "./pages/NewOrder";
import AddProduct from "./pages/AddProduct";
import Shelves from "./pages/Shelves";
import Team from "./pages/Team";
import Analytic from "./pages/Analytic";
import Manage from "./pages/Manage/Manage";
import ShiftApproval from "./pages/ShiftApproval";
import ProductRequests from "./pages/ProductRequests";
import { MarketingBlast } from "./pages/MarketingBlast";

// //
// ----------------------------------------------------------------------
// //

export default function Router() {
  const { currentUser, userData } = useAuth();

  function getChildren() {
    let children = [
      { path: "app", element: <DashboardApp /> },
      { path: "manage", element: <Manage /> },
      { path: "orders", element: <Orders /> },
      { path: "*", element: <Navigate to="/app" /> },
      { path: "", element: <Navigate to="/app" /> },
    ];
    return children;
  }

  return useRoutes([
    currentUser &&
    userData &&
    (userData.type === "manager" || userData.type === "store")
      ? {
          path: "/",
          element: <DashboardLayout />,
          children: getChildren(),
        }
      : {
          path: "/",
          element: <LogoOnlyLayout />,
          children: [
            { path: "", element: <Login /> },
            { path: "*", element: <Navigate to="/" /> },
          ],
        },
    { path: "404", element: <NotFound /> },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
