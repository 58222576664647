import { Icon } from "@iconify/react";
import clockCircleFilled from "@iconify/icons-ant-design/clock-circle-filled";
import shoppingFilled from "@iconify/icons-ant-design/shopping-filled";
import dollarCircleFilled from "@iconify/icons-ant-design/dollar-circle-filled";
import warningFilled from "@iconify/icons-ant-design/warning-filled";
import starFilled from "@iconify/icons-ant-design/star-filled";
// material
import { alpha, styled } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";
// utils
import { fShortenNumber } from "../../../utils/formatNumber";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const TOTAL = 234;

export default function DashboardStatTile({ title, color, val }) {
  const RootStyle = styled(Card)(({ theme }) => ({
    boxShadow: "none",
    textAlign: "center",
    padding: theme.spacing(5, 0),
    color: "#000000",
    backgroundColor: color,
    backgroundOpacity: 0.8,
  }));

  const IconWrapperStyle = styled("div")(({ theme }) => ({
    margin: "auto",
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: "center",
    marginBottom: theme.spacing(3),
    color: "black",
    backgroundImage: `linear-gradient(135deg, ${alpha(color, 0)} 0%, ${alpha(
      theme.palette.error.dark,
      0.24
    )} 100%)`,
  }));

  let icon = "";

  switch (title) {
    case "Avg Delivery Time":
      icon = clockCircleFilled;
      break;
    case "Orders":
      icon = shoppingFilled;
      break;
    case "Revenue":
      icon = dollarCircleFilled;
      break;
    case "Late Orders":
      icon = warningFilled;
      break;
    case "Total Cost of Goods":
      icon = dollarCircleFilled;
      break;
    case "Pre-Labor Net":
      icon = dollarCircleFilled;
      break;
    case "Avg Rating":
      icon = starFilled;
      break;
    default:
      return null;
  }

  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon icon={icon} width={24} height={24} />
      </IconWrapperStyle>
      <Typography variant="h3">{val}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {title}
      </Typography>
    </RootStyle>
  );
}
