import React, { useContext, useState, useEffect } from "react";
import { auth, db } from "../firebase";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [userData, setUserData] = useState(null);
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const [school, setSchool] = useState("USC");
  const [schools, setSchools] = useState([]);
  const [schoolData, setSchoolData] = useState({});

  console.log("School: " + school);

  useEffect(() => {
    if (currentUser) {
      db.collection("admins")
        .doc(currentUser.email)
        .get()
        .then((doc) => {
          console.log(doc.data().type);
          setUserData(doc.data());
          setSchools(doc.data().schools);
          setSchool(doc.data().schools[0]);
        });
    }
  }, [currentUser]);

  useEffect(() => {
    if (school) {
      db.collection("universities")
        .doc(school)
        .get()
        .then((doc) => {
          setSchoolData(doc.data());
        });
    }
  }, [school]);

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function logout() {
    return auth.signOut();
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    userData,
    school,
    setSchool,
    schools,
    schoolData,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
