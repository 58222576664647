import { Box } from "@mui/system";
import React, { useState, useRef, useEffect } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
mapboxgl.accessToken =
  "pk.eyJ1IjoiY2hhc2Vyb2JiaW5zIiwiYSI6ImNqZWdyZ2FpOTBkYWIycW9neGJkMGt2b2kifQ.DddYtmUbYhzlJNR5oeQvPg";

function OrderMap({
  orders,
  selectedOrder,
  setSelectedOrder,
  orderMode,
  couriers,
}) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-121.45);
  const [lat, setLat] = useState(37.45);
  const [zoom, setZoom] = useState(3);
  const [loaded, setLoaded] = useState(false);
  const oldMarkers = useRef();

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/chaserobbins/cl2fbr2zf000115n6c24zdpx7",
      center: [lng, lat],
      zoom: zoom,
    });
    map.current.on("move", () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });
  });

  function getStatusChipBackgroundColor(status, id) {
    if (id === selectedOrder) {
      return "red";
    }
    // eslint-disable-next-line default-case
    switch (status) {
      case "Recieved":
        return "orange";
      case "Packing":
        return "orange";
      case "En Route":
        return "green";
      case "Delivered":
        return "blue";
    }
  }

  function getMarkerForCourier(name) {
    // create a new div element
    const newDiv = document.createElement("div");

    newDiv.setAttribute(
      "style",
      "background-color:purple;color:white;padding:2px;border-radius:4px;font-size:10px"
    );

    // and give it some content
    const newContent = document.createTextNode(name);

    newDiv.appendChild(newContent);

    return newDiv;
  }

  function createMarkers() {
    let markers = [];
    if (oldMarkers.current) {
      oldMarkers.current.forEach((marker) => {
        marker.remove();
      });
    }
    couriers.forEach((courier) => {
      markers.push(
        new mapboxgl.Marker({
          color: "purple",
          element: getMarkerForCourier(courier.displayName),
        })
          .setLngLat([courier.location.long, courier.location.lat])
          .addTo(map.current)
      );
    });
    orders.forEach((item) => {
      if (
        (orderMode === "Active" &&
          (item.status === "Received" ||
            item.status === "Packing" ||
            item.status === "Packed" ||
            item.status === "En Route")) ||
        (orderMode === "Delivered" &&
          (item.status === "Delivered" || item.status === "Cancelled"))
      ) {
        markers.push(
          new mapboxgl.Marker({
            color: selectedOrder === item.id ? "red" : "black",
          })
            .setLngLat([item.longitude, item.latitude])
            .addTo(map.current)
        );
      }
      oldMarkers.current = markers;
    });
  }
  if (map.current) {
    createMarkers();
  }

  return (
    <>
      <Box sx={{ width: "100%", height: "100%" }}>
        <link
          href="https://api.tiles.mapbox.com/mapbox-gl-js/v2.7.0/mapbox-gl.css"
          rel="stylesheet"
        />
        <div ref={mapContainer} style={{ height: "100%", width: "100%" }} />
      </Box>
    </>
  );
}

export default OrderMap;
