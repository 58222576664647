import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  doc,
  getFirestore,
  onSnapshot,
  updateDoc,
  addDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useAuth } from "src/contexts/AuthContext";
import Page from "../../components/Page";
import CampaignIcon from "@mui/icons-material/Campaign";
import StoreIcon from "@mui/icons-material/Store";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MessageIcon from "@mui/icons-material/Message";
import PhoneIcon from "@mui/icons-material/Phone";
import NotificationsIcon from "@mui/icons-material/Notifications";
import "./Manage.css";
import { Formik } from "formik";

const Status = (props) => {
  const { open } = props;
  const { school } = useAuth();

  async function changeStatus() {
    await updateDoc(doc(getFirestore(), `universities/${school}`), {
      open: !open,
    });
  }

  return (
    <Grid item component={Card} xs={12} md={4}>
      <CardHeader title="Status" avatar={<StoreIcon />} />
      <CardContent>
        <Typography>
          The store is currently <strong>{open ? "open" : "closed"}</strong>.
        </Typography>
      </CardContent>
      <div sx={{ flex: 1 }} />
      <CardActions>
        <Button sx={{ color: open ? "red" : "green" }} onClick={changeStatus}>
          {open ? "Close" : "Open"} Store
        </Button>
      </CardActions>
    </Grid>
  );
};

const AnnouncementBanner = (props) => {
  const { announcement } = props;
  const { school } = useAuth();
  const [newAnnouncement, setNewAnnouncement] = useState("");
  const [newEmoji, setNewEmoji] = useState("");
  const [loading, setLoading] = useState(false);

  async function changeAnnouncement() {
    setLoading(true);
    await updateDoc(doc(getFirestore(), `universities/${school}`), {
      announcement: {
        content: newAnnouncement,
        emoji: newEmoji,
      },
    }).then(() => {
      setLoading(false);
      setNewAnnouncement("");
      setNewEmoji("");
    });
  }

  async function changeAnnouncementStatus() {
    await updateDoc(doc(getFirestore(), `universities/${school}`), {
      announcement: {
        enabled: !announcement.enabled,
      },
    });
  }

  return (
    <Grid item component={Card} xs={12} md={4}>
      <CardHeader title="Announcement" avatar={<CampaignIcon />} />
      <CardContent>
        {!loading ? (
          <Stack spacing={2}>
            <Typography>
              {announcement ? (
                announcement.content + " " + announcement.emoji
              ) : (
                <i>No announcement</i>
              )}
            </Typography>
            <Stack direction="row" spacing={2}>
              <TextField
                label="Content"
                sx={{ width: "100%" }}
                value={newAnnouncement}
                onChange={(e) => setNewAnnouncement(e.target.value)}
              />
              <TextField
                label="Emoji"
                sx={{ width: 85 }}
                value={newEmoji}
                onChange={(e) => setNewEmoji(e.target.value)}
              />
            </Stack>
          </Stack>
        ) : (
          <Stack direction="row" fullWidth sx={{ justifyContent: "center" }}>
            <CircularProgress />
          </Stack>
        )}
      </CardContent>
      <CardActions>
        <Button onClick={changeAnnouncement}>Update</Button>
        <Button
          sx={{ color: announcement.enabled ? "red" : "green" }}
          onClick={changeAnnouncementStatus}
        >
          {announcement.enabled ? "Disable" : "Enable"}
        </Button>
      </CardActions>
    </Grid>
  );
};

const ClosedMessage = (props) => {
  const { message } = props;
  const { school } = useAuth();
  const [newMessage, setNewMessage] = useState("");
  const [loading, setLoading] = useState(false);

  async function changeMessage() {
    setLoading(true);
    await updateDoc(doc(getFirestore(), `universities/${school}`), {
      closedMessage: newMessage,
    }).then(() => {
      setLoading(false);
      setNewMessage("");
    });
  }

  return (
    <Grid item component={Card} xs={12} md={4}>
      <CardHeader title="Closed Message" avatar={<MessageIcon />} />
      <CardContent>
        {!loading ? (
          <Stack spacing={2}>
            <Typography>{message ? message : <i>No message</i>}</Typography>
            <Stack direction="row" spacing={2}>
              <TextField
                label="Content"
                sx={{ width: "100%" }}
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
              />
            </Stack>
          </Stack>
        ) : (
          <Stack direction="row" fullWidth sx={{ justifyContent: "center" }}>
            <CircularProgress />
          </Stack>
        )}
      </CardContent>
      <CardActions>
        <Button onClick={changeMessage}>Update</Button>
      </CardActions>
    </Grid>
  );
};

const DeliveryFee = (props) => {
  const { fee } = props;
  const { school } = useAuth();
  const [newFee, setNewFee] = useState("");
  const [loading, setLoading] = useState(false);

  async function changeFee() {
    setLoading(true);
    await updateDoc(doc(getFirestore(), `universities/${school}`), {
      deliveryFee: Number(newFee),
    }).then(() => {
      setLoading(false);
      setNewFee("");
    });
  }

  return (
    <Grid item component={Card} xs={12} md={4}>
      <CardHeader title="Delivery Fee" avatar={<AttachMoneyIcon />} />
      <CardContent>
        {!loading ? (
          <Stack spacing={2}>
            <Typography variant="h3">
              {fee ? "$" + fee : <i>No fee</i>}
            </Typography>
            <Stack direction="row" spacing={2}>
              <TextField
                label="Fee"
                sx={{ width: "100%" }}
                value={newFee}
                onChange={(e) => {
                  const v = e.target.value;
                  if ((v >= 0 && !isNaN(v)) || v === "") {
                    setNewFee(v);
                  }
                }}
              />
            </Stack>
          </Stack>
        ) : (
          <Stack direction="row" fullWidth sx={{ justifyContent: "center" }}>
            <CircularProgress />
          </Stack>
        )}
      </CardContent>
      <CardActions>
        <Button onClick={changeFee}>Update</Button>
      </CardActions>
    </Grid>
  );
};

const Headline = (props) => {
  const { headline } = props;
  const { school } = useAuth();
  const [newHeadline, setNewHeadline] = useState("");
  const [loading, setLoading] = useState(false);

  async function changeHeadline() {
    setLoading(true);
    await updateDoc(doc(getFirestore(), `universities/${school}`), {
      headline: newHeadline,
    }).then(() => {
      setLoading(false);
      setNewHeadline("");
    });
  }

  return (
    <Grid item component={Card} xs={12} md={4}>
      <CardHeader title="Headline" avatar={<CampaignIcon />} />
      <CardContent>
        {!loading ? (
          <Stack spacing={2}>
            <Typography>{headline ? headline : <i>No headline</i>}</Typography>
            <Stack direction="row" spacing={2}>
              <TextField
                label="Headline"
                sx={{ width: "100%" }}
                value={newHeadline}
                onChange={(e) => setNewHeadline(e.target.value)}
              />
            </Stack>
          </Stack>
        ) : (
          <Stack direction="row" fullWidth sx={{ justifyContent: "center" }}>
            <CircularProgress />
          </Stack>
        )}
      </CardContent>
      <CardActions>
        <Button onClick={changeHeadline}>Update</Button>
      </CardActions>
    </Grid>
  );
};

const SupportPhoneNumber = (props) => {
  const { phoneNumber } = props;
  const { school } = useAuth();
  const [newPhone, setNewPhone] = useState("");
  const [loading, setLoading] = useState(false);

  async function changeSupportNumber() {
    setLoading(true);
    await updateDoc(doc(getFirestore(), `universities/${school}`), {
      phone: newPhone,
    }).then(() => {
      setLoading(false);
      setNewPhone("");
    });
  }

  return (
    <Grid item component={Card} xs={12} md={4}>
      <CardHeader title="Support Phone Number" avatar={<PhoneIcon />} />
      <CardContent>
        {!loading ? (
          <Stack spacing={2}>
            <Typography variant="h3">
              {phoneNumber ? phoneNumber : <i>No phone number</i>}
            </Typography>
            <Stack direction="row" spacing={2}>
              <TextField
                value={newPhone}
                onChange={(e) => setNewPhone(e.target.value)}
                label="Phone Number"
                sx={{ width: "100%" }}
              />
            </Stack>
          </Stack>
        ) : (
          <Stack direction="row" fullWidth sx={{ justifyContent: "center" }}>
            <CircularProgress />
          </Stack>
        )}
      </CardContent>
      <CardActions>
        <Button onClick={changeSupportNumber}>Update</Button>
      </CardActions>
    </Grid>
  );
};

export default function Manage() {
  const { school } = useAuth();
  const [data, setData] = useState(null);

  // Listen to university doc
  useEffect(() => {
    const listener = onSnapshot(
      doc(getFirestore(), `universities/${school}`),
      (doc) => {
        setData(doc.data());
      }
    );
    return () => listener();
  });

  // Render page
  return (
    <Page title="Manage | Handle">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Manage Storefront
          </Typography>
        </Stack>
        {data ? (
          <>
            <Grid container wrap="nowrap">
              <Status open={data.open} />
              {/* <DeliveryFee fee={data.deliveryFee} /> */}
              {/* <SupportPhoneNumber phoneNumber={data.phone} /> */}
            </Grid>
          </>
        ) : (
          <CircularProgress />
        )}
      </Container>
    </Page>
  );
}
