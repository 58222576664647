import React, { useEffect, useState } from "react";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch-dom";
import { connectSearchBox, connectHits } from "react-instantsearch-dom";
import { styled } from "@mui/material/styles";
import {
  Stack,
  OutlinedInput,
  InputAdornment,
  Box,
  Typography,
  Chip,
  Button,
  Card,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FilledInput,
  TextField,
} from "@mui/material";

import { db } from "src/firebase";
import { useAuth } from "src/contexts/AuthContext";
import { useNavigate } from "react-router-dom";

const searchClient = algoliasearch(
  "HGPHDG1VOI",
  "5cfbec6b6d131942f2c1e1532b44d7e0"
);

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { width: 320, boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const SearchBox = ({ currentRefinement, isSearchStalled, refine }) => (
  <>
    <Stack direction="row" alignItems={"center"}>
      <SearchStyle
        sx={{ mb: 2 }}
        value={currentRefinement}
        onChange={(e) => {
          refine(e.target.value);
        }}
        placeholder="Search product..."
        startAdornment={<InputAdornment position="start"></InputAdornment>}
      />
    </Stack>
  </>
);

const CustomSearchBox = connectSearchBox(SearchBox);

function ExistingProductEnable() {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const Hits = ({ hits }) => {
    if (hits[0]) {
      return (
        <Box>
          {hits.map((item, index) => {
            if (!item.chapman) {
              return (
                <Box
                  onClick={() => {
                    setSelectedProduct(item);
                  }}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    maxWidth: "50%",
                    borderBottomStyle: "solid",
                    borderBottomWidth: 1,
                    paddingTop: 8,
                    paddingBottom: 8,
                  }}
                >
                  <img src={item.image} style={{ height: 50, width: 50 }} />
                  {item.name} | {item.size}
                </Box>
              );
            } else return <></>;
          })}
        </Box>
      );
    } else return <></>;
  };

  const CustomHits = connectHits(Hits);
  return (
    <Box sx={{ mt: 3 }}>
      {selectedProduct ? (
        <ProductEditor item={selectedProduct} setItem={setSelectedProduct} />
      ) : (
        <>
          <InstantSearch searchClient={searchClient} indexName={"ALLPRODUCTS"}>
            <CustomSearchBox />
            <CustomHits />
          </InstantSearch>
        </>
      )}
    </Box>
  );
}

export default ExistingProductEnable;

function ProductEditor({ item, setItem }) {
  const navigate = useNavigate();
  const [suppliers, setSuppliers] = useState([]); // ALL SUPPLIERS
  const [supplierData, setSupplierData] = useState([]); // SUPPLIER SPECIFIC DATA FOR PRODUCT
  const [selectedSuppliers, setSelectedSuppliers] = useState([]); // LIST OF SUPPLIERS FOR THAT PRODUCT
  const [price, setPrice] = useState(0);
  const [shelves, setShelves] = useState([]);
  const [selectedShelf, setSelectedShelf] = useState();
  const [selectedBin, setSelectedBin] = useState();
  const { school } = useAuth();

  async function finalize() {
    // console.log(item);
    let itemObj = { ...item };

    let lowestSupplier = "";
    let lowestRate = 1000000000;
    let finalSupplierData = [];

    selectedSuppliers.forEach((supplier, index) => {
      let obj = { ...supplierData[index] };
      obj.id = selectedSuppliers[index];
      finalSupplierData.push(obj);

      let cpu =
        Number(supplierData[index].caseCost) /
        Number(supplierData[index].caseQuantity);

      if (cpu < lowestRate) {
        lowestRate = cpu;
        lowestSupplier = selectedSuppliers[index];
      }
    });

    itemObj.supplierData = finalSupplierData;
    itemObj.suppliers = selectedSuppliers;
    itemObj.primarySupplier = lowestSupplier;
    itemObj.cog = lowestRate;
    itemObj.price = Number(price);
    itemObj.quantity = 0;
    // itemObj.daysAvailable = 0;

    itemObj.location = `${selectedShelf.identifier}${selectedBin}`;

    delete itemObj.__position;
    delete itemObj._highlightResult;

    console.log(itemObj);
    db.collection("universities")
      .doc(school)
      .collection("inventory")
      .add(itemObj)
      .then(() => {
        navigate("/products");
      })
      .catch((e) => {
        alert(e);
      });
  }

  useEffect(() => {
    db.collection("universities")
      .doc(school)
      .collection("shelves")
      .orderBy("identifier", "asc")
      .get()
      .then((querySnapshot) => {
        let arr = [];
        querySnapshot.forEach((doc) => {
          let obj = doc.data();
          obj.id = doc.id;
          arr.push(obj);
        });
        setShelves([...arr]);
      });
  }, []);

  function getSuggestedPrice() {
    let cog = 0;
    let suggestion = 0;
    let lowest = 0;

    if (!(supplierData[0].caseCost && supplierData[0].caseQuantity)) {
      return 0;
    } else {
      supplierData.forEach((data, index) => {
        if (index === 0) {
          lowest = data.caseCost / data.caseQuantity;
        }
        if (data.caseCost / data.caseQuantity < lowest) {
          lowest = data.caseCost / data.caseQuantity;
        }
      });
    }

    cog = lowest;

    function roundPrice(price) {
      let int = price + "";
      let just_decimal = (
        int - Number(int.substring(0, int.indexOf(".")))
      ).toFixed(2);
      let removed_tenths = "0.0" + ("" + just_decimal)[3];
      let delta = 0.1 - removed_tenths;
      delta = Number(delta.toFixed(2));
      let final = Number(int) + (delta - 0.01);
      return final;
    }

    if (cog < 1.5) {
      suggestion = cog * 2;
    } else if (cog < 2) {
      suggestion = cog * 1.6;
    } else if (cog < 2.5) {
      suggestion = cog * 1.4;
    } else if (cog < 3) {
      suggestion = cog * 1.3;
    } else {
      suggestion = cog * 1.15;
    }
    suggestion = roundPrice(suggestion);

    return suggestion.toFixed(2);
  }

  useEffect(() => {
    db.collection("suppliers")
      .get()
      .then((querySnapshot) => {
        let arr = [];
        querySnapshot.forEach((doc) => {
          arr.push(doc.data().id);
        });
        setSuppliers(arr);
      });
  }, []);

  return (
    <Box>
      <Button
        onClick={() => {
          setItem(null);
        }}
        sx={{ mb: 1 }}
      >
        Select New Product
      </Button>
      <Card sx={{ p: 3, mt: 3 }}>
        <Stack direction="row" alignItems={"center"}>
          <img src={item.image} style={{ width: 100, height: 100 }} />
          <Box>
            <Typography variant="h4">{item.name}</Typography>
            <Chip label={item.size} />
          </Box>
        </Stack>
      </Card>

      <Card sx={{ p: 3, mt: 3 }}>
        <Stack
          sx={{ mb: 3 }}
          alignItems={"center"}
          justifyContent="space-between"
          direction="row"
        >
          <Typography variant="h6">Suppliers</Typography>
          <Button
            onClick={() => {
              let arr = [...selectedSuppliers];
              arr.push(suppliers[0]);
              setSelectedSuppliers([...arr]);

              arr = [...supplierData];
              let obj = {
                caseCost: 0.0,
                caseQuantity: 0,
                note: "",
              };
              arr.push(obj);
              setSupplierData([...arr]);
            }}
            color="success"
          >
            Add New Supplier
          </Button>
        </Stack>
        {selectedSuppliers.map((item, index) => {
          return (
            <>
              <Stack
                sx={{ mb: 1 }}
                direction="row"
                justifyContent="space-between"
                alignItems={"center"}
              >
                <Select
                  sx={{ width: "20%" }}
                  id="selectedSupplier"
                  value={selectedSuppliers[index]}
                  label="Supplier"
                  onChange={(e) => {
                    let arr = [...selectedSuppliers];
                    arr[index] = e.target.value;
                    setSelectedSuppliers([...arr]);
                  }}
                >
                  {suppliers.map((item, index) => {
                    return <MenuItem value={item}>{item}</MenuItem>;
                  })}
                </Select>
                <TextField
                  id="filled-basic"
                  label="Quantity Per Case"
                  variant="filled"
                  value={supplierData[index].caseQuantity}
                  onChange={(e) => {
                    let arr = [...supplierData];
                    arr[index].caseQuantity = e.target.value;
                    setSupplierData([...arr]);
                  }}
                />
                <FormControl variant="filled">
                  <InputLabel htmlFor="filled-adornment-amount">
                    Cost Per Case
                  </InputLabel>
                  <FilledInput
                    id="filled-adornment-amount"
                    value={supplierData[index].caseCost}
                    onChange={(e) => {
                      let arr = [...supplierData];
                      arr[index].caseCost = e.target.value;
                      setSupplierData([...arr]);
                    }}
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                  />
                </FormControl>
                <TextField
                  value={(
                    supplierData[index].caseCost /
                    supplierData[index].caseQuantity
                  ).toFixed(2)}
                  disabled
                  id="filled-basic"
                  label="Cost Per Unit"
                  variant="filled"
                />
                <Button
                  onClick={() => {
                    let arr = [...selectedSuppliers];
                    arr.splice(index, 1);
                    setSelectedSuppliers(arr);

                    arr = [...supplierData];
                    arr.splice(index, 1);
                    setSupplierData(arr);
                  }}
                  color="error"
                >
                  Remove Supplier
                </Button>
              </Stack>
              <Box>
                <TextField
                  sx={{ mb: 3 }}
                  fullWidth
                  value={null}
                  id="filled-basic"
                  label="Note (optional)"
                  variant="filled"
                  onChange={(e) => {
                    let arr = [...supplierData];
                    arr[index].note = e.target.value;
                    setSupplierData([...arr]);
                  }}
                />
              </Box>
            </>
          );
        })}
      </Card>
      {selectedSuppliers.length > 0 ? (
        <>
          <Card sx={{ p: 3, mt: 3 }}>
            <Typography variant="h6">Pricing</Typography>
            <Box>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body1">
                  {/* Suggeted Price:{" $"}
                  {getSuggestedPrice() ? getSuggestedPrice() : 0.0} */}
                </Typography>
                <FormControl variant="filled">
                  <InputLabel htmlFor="filled-adornment-amount">
                    Retail Price
                  </InputLabel>
                  <FilledInput
                    id="filled-adornment-amount"
                    value={price}
                    onChange={(e) => {
                      setPrice(e.target.value);
                    }}
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                  />
                </FormControl>
              </Stack>
            </Box>
          </Card>
          <Card sx={{ p: 3, mt: 3 }}>
            {" "}
            <Typography variant="h6">Location</Typography>
            <Box>
              <Select
                sx={{ width: "20%" }}
                id="shelfselect"
                value={selectedShelf ? selectedShelf : ""}
                renderValue={(selectedShelf) => selectedShelf.identifier}
                label="Shelf"
                onChange={(e) => {
                  setSelectedShelf(shelves[e.target.value]);
                  console.log(shelves[e.target.value]);
                }}
              >
                {shelves.map((item, index) => {
                  return <MenuItem value={index}>{item.identifier}</MenuItem>;
                })}
              </Select>
              {selectedShelf ? (
                <Select
                  sx={{ width: "20%" }}
                  id="binselect"
                  value={selectedBin ? selectedBin : ""}
                  label="Bin"
                  onChange={(e) => {
                    setSelectedBin(e.target.value);
                  }}
                >
                  {[...Array(selectedShelf.capacity).keys()].map(
                    (item, index) => {
                      return <MenuItem value={item + 1}>{item + 1}</MenuItem>;
                    }
                  )}
                </Select>
              ) : (
                <></>
              )}
            </Box>
          </Card>
        </>
      ) : (
        <></>
      )}
      {selectedSuppliers && price && selectedShelf && selectedBin ? (
        <Card sx={{ p: 3, mt: 3 }}>
          <Button
            onClick={() => {
              finalize();
            }}
            color={"success"}
          >
            Add Product to {school}
          </Button>
        </Card>
      ) : (
        <></>
      )}
    </Box>
  );
}
