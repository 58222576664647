import React, { useEffect, useState } from "react";

// material
import {
  Box,
  Grid,
  Container,
  Typography,
  CircularProgress,
  Stack,
  Button,
} from "@mui/material";
// components
import Page from "../components/Page";
import {
  AppTasks,
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppNewsUpdate,
  AppWeeklySales,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  AppConversionRates,
} from "../components/_dashboard/app";

import DashboardStatTile from "src/components/_dashboard/app/DashboardStatTile";

import { functions, db } from "../firebase";
import { useAuth } from "src/contexts/AuthContext";

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const { userData, school } = useAuth();
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [phones, setPhones] = useState([]);

  useEffect(() => {
    // fetchNumbers();
    const startOfDay = new Date();
    let hour = startOfDay.getHours();
    if (hour < 10) {
      startOfDay.setHours(10, 0, 0, 0);
      startOfDay.setDate(startOfDay.getDate() - 1);
    } else {
      startOfDay.setHours(10, 0, 0, 0);
    }
    console.log(startOfDay);
    if (userData.type) {
      const _stats = {
        orders: 0,
        rev: 0,
        late_orders: 0,
        avg_delivery_time: 0,
        totalCog: 0,
        rating: null,
        ratedOrders: 0,
      };

      let totalTimeInSeconds = 0;

      let unsub = db
        .collection("universities")
        .doc(school)
        .collection("orders")
        .where("timestamp", ">=", startOfDay)
        .get()
        .then((querySnapshot) => {
          let ordersWithRatings = 0;
          let totalRating = 0;
          querySnapshot.forEach((doc) => {
            _stats.orders += 1;
            _stats.rev += Number(doc.data().total);
            if (doc.data().ppDiscountData) {
              _stats.rev += doc.data().ppDiscountData.discount;
            }
            if (doc.data().rating) {
              ordersWithRatings++;
              totalRating += doc.data().rating;
            }
            if (doc.data().delivered_timestamp) {
              // console.log(doc.data().delivered_timestamp.seconds);
              _stats.totalCog += doc.data().totalCog;
              totalTimeInSeconds +=
                doc.data().delivered_timestamp.seconds -
                doc.data().timestamp.seconds;
              if (
                doc.data().delivered_timestamp.seconds -
                  doc.data().timestamp.seconds >
                15 * 60
              ) {
                _stats.late_orders += 1;
              }
            }
          });
          _stats.ratedOrders = ordersWithRatings;
          _stats.rating = totalRating / ordersWithRatings;
          console.log(_stats);
          _stats.rev = _stats.rev.toFixed(2);
          _stats.avg_delivery_time = totalTimeInSeconds / _stats.orders;
          setStats(_stats);
          setLoading(false);
        });
      return unsub;
    } else {
      setLoading(false);
    }
  }, [userData, school]);

  return (
    <Page title="Dashboard | Handle">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Welcome to HandleOS</Typography>
        </Box>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <DashboardStatTile
                  title={"Orders"}
                  icon={"Users"}
                  color={"#A476FF"}
                  val={stats.orders}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <DashboardStatTile
                  title={"Late Orders"}
                  icon={"Users"}
                  color={"#FFB938"}
                  val={stats.late_orders}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <DashboardStatTile
                  title={"Avg Delivery Time"}
                  icon={"Users"}
                  color={"#FFF8F1"}
                  val={(stats.avg_delivery_time / 60).toFixed(2) + "m"}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </Page>
  );
}
