import PropTypes from "prop-types";
// material
import { Grid, Stack, Button } from "@mui/material";
import ShopProductCard from "./ProductCard";
import { useEffect } from "react";

// ----------------------------------------------------------------------

ProductList.propTypes = {
  products: PropTypes.array.isRequired,
};

export default function ProductList({ loadMore, products, ...other }) {
  return (
    <>
      <Grid container spacing={3} {...other}>
        {products.map((product) => (
          <Grid key={product.id} item xs={12} sm={6} md={3}>
            <ShopProductCard product={product} />
          </Grid>
        ))}
      </Grid>
      <Stack direction="row" justifyContent="center" sx={{ mt: 3 }}>
        <Button
          onClick={() => {
            loadMore();
          }}
        >
          Load More
        </Button>
      </Stack>
    </>
  );
}
