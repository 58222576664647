import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";

export const app = firebase.initializeApp({
  apiKey: "AIzaSyCkheM4nXOfwiSEfJVa9j7Y2xSxcd8ZQ-Q",
  authDomain: "handledelivery-af957.firebaseapp.com",
  projectId: "handledelivery-af957",
  storageBucket: "handledelivery-af957.appspot.com",
  messagingSenderId: "845520094081",
  appId: "1:845520094081:web:d259c5fca1a63d72011e80",
});

export const db = app.firestore();
export const storage = firebase.storage();
export const functions = app.functions();
export const auth = app.auth();
// firebase.functions().useEmulator("localhost", 5001);
export default app;
