import {
  Modal,
  Box,
  Container,
  Button,
  CircularProgress,
  Typography,
  Stack,
  Chip,
  TextField,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAuth } from "src/contexts/AuthContext";
import { db, storage } from "src/firebase";
import { FileUploader } from "react-drag-drop-files";
import { typography } from "@mui/system";
import shortHash from "shorthash2";

const fileTypes = ["PNG"];

function EditProductModal({ showModal, setShowModal, onClose, id }) {
  const [idDupe, setIdDupe] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const { school } = useAuth();
  const [subcategories, setSubcategories] = useState([]);
  const [file, setFile] = useState(false);
  const [suppliers, setSuppliers] = useState([]); // ALL SUPPLIERS

  let random = Math.random() * 1000 + "";

  const handleChange = (file) => {
    setFile(file);
  };

  function remove() {
    db.collection(`universities/${school}/inventory`)
      .doc(id)
      .delete()
      .then(() => {
        setShowModal(false);
      });
  }

  async function submit() {
    let imgName = shortHash(data.name) + "_" + random;
    setLoading(true);
    let globalObj = {};
    let localObj = {};

    if (file) {
      //change to global
      localObj.image =
        "https://firebasestorage.googleapis.com/v0/b/handledelivery-af957.appspot.com/o/products%2F" +
        imgName +
        ".png?alt=media";

      await storage.ref("/products/" + imgName + ".png").put(file);
    }

    // globalObj.name = data.name;
    localObj.name = data.name;
    localObj.overflow = data.overflow ? data.overflow : "";
    localObj.saleText = data.saleText ? data.saleText : "";
    localObj.comparePrice = data.comparePrice ? data.comparePrice : null;

    localObj.quantity = Number(data.quantity);

    // globalObj.size = data.size;
    localObj.size = data.size;

    localObj.price = data.price;
    localObj.location = data.location;

    // globalObj.tags = data.tags;
    localObj.tags = data.tags;

    localObj.supplierData = data.supplierData;

    let arr = [];
    localObj.supplierData.forEach((supplier) => {
      arr.push(supplier.id);
    });
    localObj.suppliers = arr;

    console.log("Submitting to " + id);

    // await db
    //   .collection("products")
    //   .doc(idDupe)
    //   .set({ ...globalObj }, { merge: true });
    await db
      .collection(`universities/${school}/inventory`)
      .doc(idDupe)
      .set({ ...localObj }, { merge: true });

    setLoading(false);
    setShowModal(false);
  }

  async function fetch() {
    console.log("fetching product " + id);
    let querySnapshot = await db
      .collection("universities")
      .doc(school)
      .collection("subcategories")
      .get();
    let arr = [
      { slug: "featured", id: "featured", name: "featured", emoji: "" },
    ];
    querySnapshot.forEach((doc) => {
      arr.push(doc.data());
      console.log(doc.data());
    });
    arr.push({
      emoji: "",
      index: 100,
      name: "Trending",
      slug: "trending",
    });
    setSubcategories([...arr]);

    let supplierSnap = await db.collection("suppliers").get();
    arr = [];
    supplierSnap.forEach((supplierDoc) => {
      arr.push(supplierDoc.data().id);
    });
    setSuppliers([...arr]);

    let doc = await db
      .collection(`universities/${school}/inventory`)
      .doc(id)
      .get();
    setData({ ...doc.data() });
    setIdDupe(doc.id);
    console.log("Fetched: " + doc.id);
    console.log("Data: ", data);
    setLoading(false);
    console.log("done fetching");
  }

  useEffect(() => {
    if (showModal) {
      fetch();
    }
  }, [showModal]);

  return (
    <Modal
      open={showModal}
      onClose={() => {
        setShowModal(false);
        if (onClose) {
          onClose();
        }
      }}
    >
      {loading || !data ? (
        <CircularProgress />
      ) : (
        <Box
          style={{
            height: "100vh",
            width: "100vw",
            backgroundColor: "white",
            paddingTop: 16,
            paddingBottom: 64,
            overflowY: "scroll",
          }}
        >
          <Container>
            <Stack direction="row" justifyContent={"space-between"}>
              <Button
                onClick={() => {
                  console.log("closing");
                  setShowModal(false);
                }}
              >
                Close Without Saving
              </Button>
              <Button
                color="success"
                onClick={() => {
                  submit();
                }}
              >
                Save Changes
              </Button>
            </Stack>
            <Stack direction="column" sx={{ mt: 3 }}>
              <Stack direction="row" alignItems={"center"}>
                <img src={data.image} style={{ height: 250, width: 250 }} />
                <Box sx={{ ml: 3 }}>
                  <Typography variant="h3" alignItems={"center"}>
                    {data.name}
                  </Typography>
                  <Chip label={data.location} />
                  <Chip sx={{ ml: 1 }} label={data.quantity + " remaining"} />
                  <Chip
                    sx={{ ml: 1 }}
                    label={
                      data.rateOfSale
                        ? data.rateOfSale + " sales per day (avg)"
                        : "No rate of sale data"
                    }
                  />
                  <Chip sx={{ ml: 1 }} label={data.sales + " sales"} />
                </Box>
              </Stack>
              <Stack direction="row" style={{ width: "100%" }}>
                <Stack
                  direction="column"
                  style={{ width: "50%", paddingRight: 8 }}
                >
                  <TextField
                    sx={{ mt: 2 }}
                    id="name"
                    label="Name"
                    variant="filled"
                    value={data.name}
                    onChange={(e) => {
                      let obj = data;
                      obj.name = e.target.value;
                      setData({ ...obj });
                    }}
                  />
                  <TextField
                    sx={{ mt: 2 }}
                    id="size"
                    label="Size"
                    variant="filled"
                    value={data.size}
                    onChange={(e) => {
                      let obj = data;
                      obj.size = e.target.value;
                      setData({ ...obj });
                    }}
                  />
                  <TextField
                    sx={{ mt: 2 }}
                    id="size"
                    label="Price"
                    variant="filled"
                    value={data.price}
                    onChange={(e) => {
                      let obj = data;
                      obj.price = e.target.value;
                      setData({ ...obj });
                    }}
                  />
                  <TextField
                    sx={{ mt: 2 }}
                    id="saleText"
                    label="Promo Text"
                    variant="filled"
                    value={data.saleText ? data.saleText : ""}
                    onChange={(e) => {
                      let obj = data;
                      obj.saleText = e.target.value;
                      setData({ ...obj });
                    }}
                  />
                  <TextField
                    sx={{ mt: 2 }}
                    id="comparePrice"
                    label="Compare Price"
                    variant="filled"
                    value={data.comparePrice ? data.comparePrice : ""}
                    onChange={(e) => {
                      let obj = data;
                      obj.comparePrice = Number(e.target.value);
                      setData({ ...obj });
                    }}
                  />
                </Stack>
                <Stack
                  direction="column"
                  style={{ width: "50%", paddingLeft: 8 }}
                >
                  <TextField
                    sx={{ mt: 2 }}
                    id="quantity"
                    label="Quantity"
                    type="number"
                    variant="filled"
                    value={data.quantity}
                    onChange={(e) => {
                      let obj = data;
                      obj.quantity = e.target.value;
                      setData({ ...obj });
                    }}
                  />
                  <Box sx={{ mt: 2, width: "100%" }}>
                    <FileUploader
                      style={{ width: "100%" }}
                      handleChange={handleChange}
                      name="file"
                      types={fileTypes}
                    />
                  </Box>
                  <TextField
                    sx={{ mt: 2 }}
                    id="location"
                    label="Location"
                    variant="filled"
                    value={data.location}
                    onChange={(e) => {
                      let obj = data;
                      obj.location = e.target.value;
                      setData({ ...obj });
                    }}
                  />
                  <TextField
                    sx={{ mt: 2 }}
                    id="overflow"
                    label="Overflow"
                    variant="filled"
                    value={data.overflow ? data.overflow : ""}
                    onChange={(e) => {
                      let obj = data;
                      obj.overflow = e.target.value;
                      setData({ ...obj });
                    }}
                  />
                </Stack>
              </Stack>
              <Box sx={{ mt: 2 }}>
                <Typography variant="h5">Tags</Typography>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {subcategories.map((item, index) => {
                    return (
                      <Box style={{ width: "25%" }}>
                        <Checkbox
                          onChange={(e) => {
                            let obj = data;
                            if (e.target.checked) {
                              obj.tags.push(item.slug);
                            } else {
                              obj.tags.splice(obj.tags.indexOf(item.slug), 1);
                            }
                            setData({ ...obj });
                          }}
                          checked={
                            data.tags.indexOf(item.slug) >= 0 ? true : false
                          }
                          label={item.name}
                        />
                        <span>
                          {item.name} {item.emoji}
                        </span>
                      </Box>
                    );
                  })}
                </Box>
                <Box>
                  <Stack
                    direction="row"
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography sx={{ mt: 3 }} variant="h5">
                      Suppliers
                    </Typography>
                    <Button
                      onClick={() => {
                        let obj = data;
                        let sample = {
                          caseCost: "0.00",
                          caseQuantity: "0",
                          id: suppliers[0],
                          note: "",
                        };
                        obj.supplierData.push(sample);
                        setData({ ...obj });
                      }}
                    >
                      Add New Supplier
                    </Button>
                  </Stack>
                  {data.supplierData.map((item, index) => {
                    return (
                      <Box
                        style={{
                          borderBottomColor: "lightgrey",
                          borderBottomStyle: "solid",
                          borderBottomWidth: 1,
                          paddingBottom: 8,
                          paddingTop: 8,
                        }}
                      >
                        <Stack
                          direction="row"
                          justifyContent={"space-between"}
                          alignItems="center"
                        >
                          <Select
                            id="id"
                            value={data.supplierData[index].id}
                            label="Supplier"
                            onChange={(e) => {
                              let obj = data;
                              obj.supplierData[index].id = e.target.value;
                              setData({ ...obj });
                            }}
                          >
                            {suppliers.map((_item, _index) => {
                              return <MenuItem value={_item}>{_item}</MenuItem>;
                            })}
                          </Select>
                          <TextField
                            sx={{ mt: 2 }}
                            id="casecost"
                            label="Case Cost"
                            variant="filled"
                            value={data.supplierData[index].caseCost}
                            onChange={(e) => {
                              let obj = data;
                              obj.supplierData[index].caseCost = e.target.value;
                              setData({ ...obj });
                            }}
                          />
                          <TextField
                            sx={{ mt: 2 }}
                            id="caseq"
                            label="Case Quantity"
                            variant="filled"
                            value={data.supplierData[index].caseQuantity}
                            onChange={(e) => {
                              let obj = data;
                              obj.supplierData[index].caseQuantity =
                                e.target.value;
                              setData({ ...obj });
                            }}
                          />
                          <TextField
                            sx={{ mt: 2 }}
                            disabled
                            label="Cost per Unit"
                            variant="filled"
                            value={
                              "$" +
                              (
                                Number(data.supplierData[index].caseCost) /
                                Number(data.supplierData[index].caseQuantity)
                              ).toFixed(2)
                            }
                            onChange={(e) => {
                              let obj = data;
                              obj.supplierData[index].caseQuantity =
                                e.target.value;
                              setData({ ...obj });
                            }}
                          />
                          <Button
                            onClick={() => {
                              let obj = data;
                              obj.supplierData.splice(index, 1);
                              setData({ ...obj });
                            }}
                            color="error"
                          >
                            Remove
                          </Button>
                        </Stack>
                        <TextField
                          sx={{ mt: 2 }}
                          fullWidth
                          id="notes"
                          label="Notes"
                          variant="filled"
                          value={data.supplierData[index].note}
                          onChange={(e) => {
                            let obj = data;
                            obj.supplierData[index].note = e.target.value;
                            setData({ ...obj });
                          }}
                        />
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Stack>
            <Button
              onClick={() => {
                if (
                  window.confirm("ARE YOU SURE YOU WANT TO DELETE THIS PRODUCT")
                ) {
                  remove();
                } else {
                  alert("Cancelled");
                }
              }}
              sx={{ mt: 3 }}
              color="error"
            >
              DANGER!!!! Delete Item from Store
            </Button>
          </Container>
        </Box>
      )}
    </Modal>
  );
}

export default EditProductModal;
