import React, { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";
import { db, functions } from "src/firebase";
import {
  Box,
  Chip,
  CircularProgress,
  Stack,
  Typography,
  Menu,
  MenuItem,
  Button,
  Divider,
} from "@mui/material";
import CancelOrder from "src/api/CancelOrder";

function OrderDetails({ selectedOrder, orders, getOrders }) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const { school } = useAuth();
  const [showMenu, setShowMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [userData, setUserData] = useState(null);
  const [totalProducts, setTotalProducts] = useState(0);

  function openMenu(e) {
    setAnchorEl(e.currentTarget);
    setShowMenu(true);
  }

  function closeMenu() {
    setShowMenu(false);
  }

  useEffect(() => {
    console.log(selectedOrder);
    fetch();
  }, [selectedOrder, orders]);

  async function fetch() {
    if (selectedOrder) {
      let order = await db
        .collection(`universities/${school}/orders`)
        .doc(selectedOrder)
        .get();
      let user = await db
        .collection("users")
        .doc(order.data().customerID)
        .get();
      setUserData(user.data());

      let prods = 0;
      order.data().products.forEach((product) => {
        prods += product.quantity;
      });
      setTotalProducts(prods);

      setData(order.data());
      setLoading(false);
    }
  }

  if (loading && selectedOrder) return <CircularProgress />;

  if (loading && !selectedOrder) return <></>;

  return (
    <Box>
      <Stack>
        <Box>
          <Stack
            direction={"row"}
            alignItems="center"
            sx={{ width: "100%" }}
            justifyContent="space-between"
          >
            <Typography variant="h3">
              {data.customer.indexOf(" ") !== -1
                ? data.customer.substring(0, data.customer.indexOf(" "))
                : data.customer}
            </Typography>
            <Box>
              <Chip color="success" label={"$" + data.total} sx={{ mr: 1 }} />
              {userData && <Chip label={"Order " + userData.orders.length} />}
              <Button color="info" onClick={openMenu}>
                ...
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={showMenu}
                onClose={closeMenu}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    alert(data.phone);
                  }}
                >
                  Call Customer
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    alert(data.phone);
                  }}
                >
                  Text Customer
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    CancelOrder(school, selectedOrder, true);
                    alert("Cancelling...");
                  }}
                >
                  Cancel order and refund (YOU MUST NOTIFY CUSTOMER WHY)
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    CancelOrder(school, selectedOrder, false);
                    alert("Cancelling...");
                  }}
                >
                  Cancel order without refund (YOU MUST NOTIFY CUSTOMER WHY)
                </MenuItem>
              </Menu>
            </Box>
          </Stack>
          <Stack direction="column">
            <Typography variant="subtitle">{data.address}</Typography>
            <Typography variant="subtitle">
              Placed @{" "}
              {new Date(data.timestamp.seconds * 1000).toLocaleString()}
            </Typography>
            {data.delivered_timestamp ? (
              <>
                <Typography variant="subtitle">
                  Delivered @{" "}
                  {new Date(
                    data.delivered_timestamp.seconds * 1000
                  ).toLocaleString()}
                </Typography>
              </>
            ) : (
              <></>
            )}
            {data.courierName ? (
              <>
                <Typography variant="subtitle">{data.courierName}</Typography>
              </>
            ) : (
              <></>
            )}
            {totalProducts >= 4 ? (
              <>
                <Typography variant="h4">
                  CONFIRM: {totalProducts} PRODUCTS
                </Typography>
              </>
            ) : (
              <></>
            )}

            {data.status !== "Delivered" ? (
              <Stack
                sx={{ mt: 1 }}
                direction="row"
                justifyContent={"space-evenly"}
              >
                <Chip
                  style={{
                    width: "30%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "red",
                    color: "white",
                    padding: 8,
                    cursor: "pointer",
                    borderColor: "black",
                    borderStyle: "solid",
                  }}
                  onClick={() => {
                    db.collection(`universities/${school}/orders`)
                      .doc(selectedOrder)
                      .set(
                        {
                          status: "Packing",
                        },
                        { merge: true }
                      )
                      .then(() => {
                        console.log("Updated status to Packing");
                        getOrders();
                      });
                  }}
                  label="Packing"
                  color="warning"
                ></Chip>
                <Chip
                  style={{
                    width: "30%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "yellow",
                    color: "black",
                    padding: 8,
                    cursor: "pointer",
                    borderColor: "black",
                    borderStyle: "solid",
                  }}
                  onClick={() => {
                    console.log("school", school);
                    db.collection(`universities/${school}/orders`)
                      .doc(selectedOrder)
                      .update({
                        status: "Packed",
                      })
                      .then(() => {
                        console.log("Updated status to Packed2");
                        getOrders();
                      });
                  }}
                  label="Packed"
                  color="primary"
                ></Chip>
                <Chip
                  style={{
                    width: "30%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "white",
                    padding: 8,
                    cursor: "pointer",
                    borderColor: "black",
                    borderStyle: "solid",
                  }}
                  onClick={() => {
                    db.collection(`universities/${school}/orders`)
                      .doc(selectedOrder)
                      .set(
                        {
                          status: "En Route",
                        },
                        { merge: true }
                      )
                      .then((res) => {
                        console.log("Updated status to En Route", res);
                        getOrders();
                      });
                  }}
                  color="success"
                  label="En Route"
                ></Chip>
              </Stack>
            ) : (
              <></>
            )}
          </Stack>
          <br />
        </Box>
        <Stack direction="column">
          {data.products.map((item, index) => {
            return (
              <Item
                key={index}
                data={item}
                index={index}
                orderID={selectedOrder}
                customer={data.customerID}
              />
            );
          })}
        </Stack>
      </Stack>
    </Box>
  );
}

export default OrderDetails;

function Item({ data, index, customer, orderID }) {
  const [showMenu, setShowMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const refundProduct = functions.httpsCallable("refundProduct");

  function openMenu(e) {
    setAnchorEl(e.currentTarget);
    setShowMenu(true);
  }

  function closeMenu() {
    setShowMenu(false);
  }

  return (
    <Stack
      style={{
        paddingTop: 8,
        paddingBottom: 8,
        borderBottomColor: "grey",
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
      }}
      direction="row"
      justifyContent={"space-between"}
    >
      <Stack direction="row">
        <img src={data.image} style={{ width: 100, height: 100 }} />
        <Stack sx={{ ml: 1 }}>
          <Typography variant="h5">{data.name}</Typography>
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={2}
          >
            <Typography
              color={data.quantity > 1 ? "red" : "black"}
              variant="h6"
            >
              {data.quantity}x
            </Typography>
            <Typography variant="h6">{data.location}</Typography>
            <Chip label={data.size} />
            {/* <Chip label={data.obj.quantity + " remaining"} /> */}
          </Stack>
        </Stack>
      </Stack>
      <Button color="inherit" onClick={openMenu}>
        ...
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={showMenu}
        onClose={closeMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/* <MenuItem onClick={closeMenu}>Replace</MenuItem> */}
        <MenuItem
          onClick={() => {
            refundProduct({
              user: customer,
              orderID: orderID,
              itemIndex: index,
            }).then(() => {
              alert("Item refunded.");
            });
            // alert("This is currently under work. Please message your OM!");
            closeMenu();
          }}
        >
          Refund
        </MenuItem>
      </Menu>
    </Stack>
  );
}
