import { Icon } from "@iconify/react";
import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import peopleFill from "@iconify/icons-eva/people-fill";
import shoppingBagFill from "@iconify/icons-eva/shopping-bag-fill";
import archiveFill from "@iconify/icons-eva/archive-fill";
import pricetagsFill from "@iconify/icons-eva/pricetags-fill";
import listFill from "@iconify/icons-eva/list-fill";
import barChart2Fill from "@iconify/icons-eva/bar-chart-2-fill";
import clockOutline from "@iconify/icons-eva/clock-outline";
import settings2Fill from "@iconify/icons-eva/settings-2-fill";

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: "dashboard",
    path: "/app",
    icon: getIcon(pieChart2Fill),
  },
  {
    title: "manage",
    path: "/manage",
    icon: getIcon(settings2Fill),
  },
  {
    title: "orders",
    path: "/orders",
    icon: getIcon(listFill),
  },
];

export default sidebarConfig;
