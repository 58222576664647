// material
import { useState, useEffect } from "react";
import {
  Container,
  Stack,
  Typography,
  InputAdornment,
  Button,
  OutlinedInput,
  Select,
} from "@mui/material";
import { db } from "src/firebase";
import { styled } from "@mui/material/styles";
// components
import Page from "../components/Page";
import { ProductList } from "../components/_dashboard/products";

import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  connectSearchBox,
  RefinementList,
  Configure,
} from "react-instantsearch-dom";

import { connectInfiniteHits } from "react-instantsearch-dom";
import { useAuth } from "src/contexts/AuthContext";
import { useNavigate } from "react-router-dom";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { width: 320, boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const searchClient = algoliasearch(
  "HGPHDG1VOI",
  "5cfbec6b6d131942f2c1e1532b44d7e0"
);

const Hits = ({ hits, refineNext }) => {
  return <ProductList loadMore={refineNext} products={hits} />;
};

const CustomHits = connectInfiniteHits(Hits);

// ----------------------------------------------------------------------

export default function EcommerceShop() {
  const navigate = useNavigate();
  const { schoolData } = useAuth();
  const [filter, setFilter] = useState(null);
  const [subcats, setSubcats] = useState([]);

  useEffect(() => {
    db.collection(`universities/${schoolData.id}/subcategories`)
      .get()
      .then((querySnapshot) => {
        let subcategories = [];
        querySnapshot.forEach((doc) => {
          subcategories.push(doc.id);
        });
        setSubcats([...subcategories]);
      });
  }, [schoolData]);

  const SearchBox = ({ currentRefinement, isSearchStalled, refine }) => (
    <>
      <Stack
        direction="row"
        alignItems={"center"}
        style={{
          width: "100%",
          justifyContent: "space-between",
          marginBottom: 16,
        }}
      >
        <SearchStyle
          value={currentRefinement}
          onChange={(e) => {
            refine(e.target.value);
          }}
          placeholder="Search product..."
          startAdornment={<InputAdornment position="start"></InputAdornment>}
        />
        <FormControl style={{ width: "25%" }}>
          <InputLabel id="category-select">Product Category</InputLabel>
          <Select
            labelId="category-select"
            id="category-select"
            value={filter}
            label="Product Category"
            onChange={(e) => {
              setFilter(e.target.value);
            }}
          >
            <MenuItem value={null}>none</MenuItem>
            {subcats.map((item) => {
              return <MenuItem value={item}>{item}</MenuItem>;
            })}
          </Select>
        </FormControl>
        <Button
          onClick={() => {
            navigate("/addproduct");
          }}
        >
          Add Product
        </Button>
      </Stack>
    </>
  );

  const CustomSearchBox = connectSearchBox(SearchBox);

  console.log(filter);

  return (
    <Page title="Inventory | Handle">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Products
        </Typography>
        <InstantSearch
          searchClient={searchClient}
          indexName={schoolData.algolia_id}
        >
          <Configure
            filters={filter ? `tags:${filter}` : "quantity>-10000"}
            hitsPerPage={100}
          />
          <CustomSearchBox />
          <CustomHits />
        </InstantSearch>
      </Container>
    </Page>
  );
}
