import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// material
import { Box, Card, Link, Typography, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
// utils
import { fCurrency } from "../../../utils/formatNumber";
//
import Label from "../../Label";
import ColorPreview from "../../ColorPreview";
import { useState } from "react";

import EditProductModal from "src/components/EditProductModal";

// ----------------------------------------------------------------------

const ProductImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
};

export default function ShopProductCard({ product }) {
  const { name, image, price, quantity, location } = product;
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <EditProductModal
        showModal={showModal}
        setShowModal={setShowModal}
        id={product.objectID}
      />

      <Card
        style={{
          cursor: "pointer",
        }}
        onClick={() => {
          console.log("clicked");
          setShowModal(true);
        }}
      >
        <Box sx={{ pt: "100%", position: "relative" }}>
          <Label
            color={
              quantity > 10 ? "default" : quantity >= 0 ? "error" : "warning"
            }
            variant="filled"
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: "absolute",
              textTransform: "uppercase",
            }}
          >
            {quantity ? quantity : "OOS"}
          </Label>
          <ProductImgStyle sx={{ p: 1 }} alt={name} src={image} />
        </Box>

        <Stack spacing={2} sx={{ p: 3 }}>
          <Link to="#" color="inherit" underline="hover" component={RouterLink}>
            <Typography variant="subtitle2" noWrap>
              {name}
            </Typography>
          </Link>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="subtitle1">
              &nbsp;
              {fCurrency(price)}
            </Typography>
            <Typography variant="subtitle1">{location}</Typography>
          </Stack>
        </Stack>
      </Card>
    </>
  );
}
